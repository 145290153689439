<template>
  <div class="new_order">
    <div><i class="el-icon-arrow-left order_return" @click="$router.go(-1)"></i></div>
    <div class="new_order_row">
      <div class="o_title">{{$t('main.text206')}}</div>
      <div class="row_form">
        <el-form :inline="true" label-position="right" label-width="auto">
          <el-form-item :label="$t('main.text207')">
            <el-input
              :disabled="inp_dab"
              v-model="o_row1.job"
              :placeholder="$t('main.text208')"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <div class="new_order_row">
      <div class="o_title">{{$t('main.text209')}}</div>
      <div class="row_form">
        <el-form :inline="true" label-position="right" label-width="100px">
          <div class="form_margin_btm">
            <el-form-item :label="$t('main.text210')" style="margin-bottom: 0">
              <el-select
                v-model="o_row4.way"
                :placeholder="$t('main.text211')"
                @change="way_change"
              >
                <el-option :label="$t('main.text212')" value="2"></el-option>
                <el-option :label="$t('main.text213')" value="1"></el-option>
                <!-- <el-option label="两者均可" value="3"></el-option> -->
              </el-select>
              <span style="margin-left:20px;" v-if="o_row4.way==2">
                <el-checkbox v-model="is_platform">{{$t('main.text214')}}</el-checkbox>
              </span>
            </el-form-item>

            <el-form-item :label="$t('main.text215')" v-if="(o_row4.way != 2)||(is_platform)">
              <el-select
                v-model="o_row4.platform"
                :placeholder="$t('main.text219')">
                <el-option label="Zoom" value="Zoom"></el-option>
              </el-select>
            </el-form-item>

          </div>
          <div class="form_margin_btm">
            <el-form-item
              :label="$t('main.text218')"
              class="inp_min_width"
              v-if="o_row4.way != 1"
            >
              <el-input
                v-model="o_row4.location"
                :placeholder="$t('main.text219')"
              ></el-input>
            </el-form-item>
          </div>
          <div class="form_margin_btm">
            <!-- <el-form-item
              label="面试链接："
              class="inp_min_width"
              v-if="o_row4.way != 2"
            >
              <el-input
                v-model="o_row4.online_link"
                placeholder="请输入面试链接（可暂不提供）"
              ></el-input>
            </el-form-item> -->
          </div>
          <div class="form_margin_btm">
            <el-form-item :label="$t('main.text220')" class="inp_min_width posi_re">
              <el-input
                v-model="o_row4.time"
                :placeholder="$t('main.text221')"
              ></el-input>
              <span class="span_time">{{$t('main.text128')}}</span>
            </el-form-item>
          </div>
          <div class="form_margin_btm">
            <el-form-item :label="$t('main.text222')">
              <el-radio-group v-model="o_row4.invitation_method" :disabled="inp_dab">
                <el-radio label="1">{{$t('main.text223')}}</el-radio>
                <el-radio label="2">{{$t('main.text224')}}</el-radio>
              </el-radio-group>
            </el-form-item>
          </div>
        </el-form>
      </div>
    </div>

    <div class="new_order_row">
      <div class="o_title">{{$t('main.text225')}}</div>
      <div class="row_form">
        <el-form :inline="true" label-position="right" label-width="auto">
          <div>
            <el-form-item :label="$t('main.text226')">
              <el-input
                v-model="o_row2.name"
                :placeholder="$t('main.text227')"
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('main.text228')">
              <el-input
                v-model="o_row2.mobile"
                :placeholder="$t('main.text229')"
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('main.text230')">
              <el-input
                v-model="o_row2.email"
                :placeholder="$t('main.text231')"
              ></el-input>
            </el-form-item>
          </div>
        </el-form>
      </div>
    </div>

    <div class="new_order_row">
      <div class="o_title">{{$t('main.text232')}}</div>
      <div class="row_form">
        <el-form :inline="true" label-position="right" label-width="auto">
          <div class="form_margin_btm" v-for="(item, index) in interval" :key="index">
            <el-form-item :label="$t('main.text232')">
              <el-date-picker
                :disabled="o_row2.o_row2_dab"
                v-model="item.start"
                type="datetime"
                :picker-options="pickerOptions"
                value-format="timestamp"
                :placeholder="$t('main.text139')"
                @change="time_change(index)"
              >
              </el-date-picker>
              <span class="parting_line"> — </span>
              <el-date-picker
                :disabled="o_row2.o_row2_dab"
                v-model="item.end"
                type="datetime"
                :picker-options="pickerOptions"
                value-format="timestamp"
                :placeholder="$t('main.text139')"
                @change="time_change(index)"
              >
              </el-date-picker>
              <span class="parting_line"> &nbsp; </span>
            <el-checkbox :disabled="o_row2.o_row2_dab" v-model="item.skip_time">{{$t('main.text144')}}</el-checkbox>

            <template v-if="inp_dab">
              <el-radio :disabled="o_row2.o_row2_dab||item.status_dab" v-model="item.status" :label="1">{{$t('main.text233')}}</el-radio>
              <el-radio :disabled="o_row2.o_row2_dab||item.status_dab" v-model="item.status" :label="5">{{$t('main.text234')}}</el-radio>
            </template>
            
            </el-form-item>
          </div>
          <div class="type3" v-if="time_err_txt3" style="
            margin-left: 82px;
            margin-bottom: 20px;">
            {{$t('main.text235')}}
          </div>
          <div class="form_margin_btm">
            <el-form-item label=" " v-if="!o_row2.o_row2_dab">
              <el-button
                plain
                class="order_btn"
                size="small"
                icon="el-icon-plus"
                @click="add_row2_time_edit"
                >{{$t('main.text236')}}</el-button
              >
            </el-form-item>
          </div>
          <div>
            <el-form-item label=" ">
              <el-button plain class="search_btn" @click="o_row2.o_row2_dab=true">{{$t('main.text4')}}</el-button>
              <el-button plain class="reset_btn" @click="o_row2_reset">{{$t('main.text13')}}</el-button>
            </el-form-item>
          </div>
        </el-form>
      </div>
    </div>

    <div class="new_order_row">
      <div class="o_title">
        {{$t('main.text237')}}
        <el-button
          v-if="!o_row3_dab"
          style="margin-left: 50px"
          class="order_btn blue_bgc"
          size="small"
          icon="el-icon-plus"
          @click="open_candidate_dlg"
          >{{$t('main.text238')}}</el-button
        >
      </div>
      <div class="row_form" style="padding-left: 15px">
        <el-form :inline="true" label-position="right" label-width="100px">
          <div class="hint">{{$t('main.text239')}}</div>

          <template>
            <div v-for="(item, index) in o_row3" :key="index">
              <span class="my_label">{{ index + 1 }}</span>
              <el-form-item :label="$t('main.text226')">
                <el-input
                  :disabled="o_row3_dab"
                  v-model="item.name"
                  :placeholder="$t('main.text254')"
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('main.text228')">
                <el-input
                  :disabled="o_row3_dab"
                  v-model="item.mobile"
                  :placeholder="$t('main.text229')"
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('main.text230')">
                <el-input
                  :disabled="o_row3_dab"
                  v-model="item.email"
                  :placeholder="$t('main.text231')"
                ></el-input>
              </el-form-item>
              <el-form-item label="" v-if="o_row3.length>1">
                <span class="type3_c" @click="r3_delect(index)">{{$t('main.text241')}}</span>
              </el-form-item>
              <el-form-item label="" v-if="o_row3.length>1">
                <span class="type2_c" v-if="index!=0" @click="tabbing_order(1,index)">{{$t('main.text242')}}&nbsp;</span>
                <span class="type2_c" v-if="index!=o_row3.length-1" @click="tabbing_order(2,index)">{{$t('main.text243')}}</span>
              </el-form-item>
            </div>
          </template>

          <div>
            <el-form-item label="">
              <el-button plain class="order_btn" icon="el-icon-plus" @click="btn_add_candidate">{{$t('main.text244')}}</el-button>
            </el-form-item>
          </div>

          <div>
            <el-form-item style="margin-top: 20px;">
              <el-button plain class="search_btn" @click="o_row3_dab=true">{{$t('main.text4')}}</el-button>
              <el-button plain class="reset_btn" @click="o_row3_reset">{{$t('main.text13')}}</el-button>
            </el-form-item>
          </div>

        </el-form>
      </div>
    </div>
    
    <div class="new_order_row">
      <div class="o_title">{{$t('main.text245')}}</div>
      <div class="row_form">
        <el-form :inline="true" label-position="right" label-width="auto">
          <el-form-item :label="$t('main.text246')">
            <el-date-picker
                :disabled="inp_dab"
                v-model="o_row5.start_invite_time"
                type="datetime"
                :picker-options="pickerOptions"
                value-format="timestamp"
                :placeholder="$t('main.text134')"
                @change="start_invite_time_change"
              >
              </el-date-picker>
              <div class="type3" v-if="time_err_txt1">{{$t('main.text247')}}</div>
              <div class="type3" v-if="time_err_txt2">{{$t('main.text248')}}</div>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <div>
      <el-button plain class="blue_bgc order_submit" @click="workOrder_edit">{{$t('main.text120')}}</el-button>
    </div>

    <!-- 候选人列表 弹框 -->
    <el-dialog
      :title="$t('main.text85')"
      :visible.sync="candidate_dlg"
      width="70%"
      top="5%"
      center
    >
      <div>
        <div class="candidate_dlg_box">
          <div class="h_table">
            <div class="h_table_top">
              <div class="t_top_1">
                
              </div>
              <div class="t_top_Right">
                <div class="t_top_3">
                  <el-input
                    :placeholder="$t('main.text98')"
                    v-model="form.search_content"
                  ></el-input>
                  <i class="el-icon-search"></i>
                </div>
                <div>
                  <el-button plain class="search_btn" @click="search"
                    >{{$t('main.text99')}}</el-button
                  >
                  <el-button plain class="reset_btn" @click="reset"
                    >{{$t('main.text13')}}</el-button
                  >
                </div>
              </div>
            </div>
            <el-table
              :data="tableData"
              :cell-style="{textAlign:'center',color:'#333'}"
              :header-cell-style="{textAlign:'center',background:'#ECF3FD',color:'#838383'}"
            >
              <el-table-column width="100">
                <span slot-scope="props">
                  <el-checkbox
                    v-model="props.row.work_order_checked"
                    @change="table_checked(props.row)"
                    >&nbsp;</el-checkbox
                  >
                </span>
              </el-table-column>

              <el-table-column :label="$t('main.text87')">
                <span slot-scope="props">
                  <div>{{ props.row.name }}</div>
                </span>
              </el-table-column>
              <el-table-column :label="$t('main.text88')">
                <span slot-scope="props">
                  <div>{{ props.row.mobile }}</div>
                </span>
              </el-table-column>
              <el-table-column :label="$t('main.text89')">
                <span slot-scope="props">
                  <div>{{ props.row.email }}</div>
                </span>
              </el-table-column>
              <!-- <el-table-column label="岗位">
                <span slot-scope="props">
                  <div>{{ props.row.job }}</div>
                </span>
              </el-table-column> -->
              <el-table-column :label="$t('main.text92')">
                <span slot-scope="props">
                  <!-- <span class="type2">已入职</span> -->
                  <div>
                    <span class="type3" v-if="props.row.status == 1"
                      >{{$t('main.text250')}}</span
                    >
                    <span class="type3" v-else-if="props.row.status == 5"
                      >{{$t('main.text251')}}</span
                    >
                  </div>
                </span>
              </el-table-column>
            </el-table>

            <el-pagination
              class="pagination_box"
              v-if="total"
              background
              @size-change="size_change"
              @current-change="current_change"
              :current-page="currentPage"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="limit"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="candidate_dlg_confirm"
          class="confirm_btn"
          >{{$t('main.text4')}}</el-button
        >
        <el-button @click="candidate_dlg = false" class="cancel_btn"
          >{{$t('main.text5')}}</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
// import crypto from "@/crypto/crypto";
import {
  workOrder_get,
  candidates_list,
  editNotStart,
  editAfterStart,
} from "@/api/api";
import moment from 'moment';

export default {
  data() {
    return {
      o_row1: {
        job: "",
      },
      o_row2: {
        name: "",
        mobile: "",
        country_code: "86",
        email: "",
        o_row2_dab:false,
      },
      interval:[
        {
          start: "",
          end: "",
          skip_time: 0, //是否跳过12点到13点
          status:1,
          status_dab:false,
          interval_id:"",
        },
      ],
      o_row3: [
        {
          name: "",
          mobile: "",
          email: "",
        },
      ],
      o_row3_dab:false,
      o_row4: {
        way: "2",
        time: "",
        invitation_method: "",
        location: "",
        online_link: "",
        platform: "",
      },
      o_row5:{
        start_invite_time: "", //开启⼯单时间戳，0表示未选时间
      },
      work_edit_id: this.$route.query.work_edit_id,

      // 候选人
      candidate_dlg: false,
      currentPage: 1, //选中的页数
      page: 1, //分页 从 1 开始
      limit: 10, //每页条数
      total: 0, //一共多少条
      tableData: [],
      form: {
        search_content: "",
      },
      new_work_data:[],
      // 1 editNotStart  不是1 editAfterStart
      status: this.$route.query.status,
      inp_dab:false,
      old_interval:[],
      old_o_row3:[],
      pickerOptions:{
        disabledDate(time){
          return time.getTime() < Date.now() - 24 * 60 * 60 * 1000;
        }
      },
      time_err_txt1:false,
      time_err_txt2:false,
      is_platform:false,
      time_err_txt3:false,

    };
  },
  beforeMount() {
    if(this.status==1){
      this.inp_dab = false;
    }else{
      this.inp_dab = true;
    }
  },
  beforeDestroy() {},
  mounted() {
    this.get_workOrder_edit_list();
    // this.set_initial_time(0);
  },
  methods: {
    // 设置默认时间 开始的时间默认是早上9点，结束时间默认是晚上6点
    set_initial_time(ind) {
      // 创建一个新的 Date 对象，表示当前时间
      let now = new Date();
      let year = now.getFullYear(); // 年份
      let month = now.getMonth() + 1; // 月份（注意：月份从0开始，所以需要加1）
      let day = now.getDate() +1; // 日
      let c_time = `${year}-${month}-${day} 09:00:00`;
      let e_time = `${year}-${month}-${day} 18:00:00`;
      // 时间戳
      let c_time_stamp = new Date(c_time).getTime();
      let e_time_stamp = new Date(e_time).getTime();
      // console.log(c_time_stamp);
      // console.log(e_time_stamp);
      this.interval[ind].start = c_time_stamp;
      this.interval[ind].end = e_time_stamp;
    },
    add_row2_time_edit(){
      this.interval.push({
        start: "",
        end: "",
        skip_time: 0, //是否跳过12点到13点
        status: 1,
        status_dab:false,
        interval_id:"",
      });
      // this.set_initial_time(this.interval.length-1);
    },
    way_change() {
      this.o_row4.location = "";
      this.o_row4.platform = "";
      this.o_row4.online_link = "";
      this.is_platform=false;
    },
    btn_add_candidate(){
      let leng_th = this.o_row3.length - 1;
      if (leng_th < 0) return;
      if (
        !this.o_row3[leng_th].name ||
        !this.o_row3[leng_th].mobile ||
        !this.o_row3[leng_th].email
      ) {
        return false;
      }
      this.o_row3.push({
        name: "",
        mobile: "",
        email: "",
      });
    },
    async workOrder_edit() {
      // console.log("编辑");
      if(!this.inp_dab){
        if (!this.o_row1.job) {
          return this.msg("error", this.$t('main.text208'));
        }
      }
      if (!this.o_row2.name) {
        return this.msg("error", this.$t('main.text227'));
      }
      // if (!this.o_row2.mobile) {
      //   return this.msg("error", "请输入手机号");
      // }
      if (!this.o_row2.email) {
        return this.msg("error", this.$t('main.text231'));
      }
      // if (!this.interval[0].start || !this.interval[0].end) {
      //   return this.msg("error", "请选择时间 ");
      // }
      if (!this.o_row3[0].name) {
        return this.msg("error", this.$t('main.text240'));
      }
      if (!this.o_row3[0].mobile) {
        return this.msg("error", this.$t('main.text229'));
      }
      if (!this.o_row3[0].email) {
        return this.msg("error", this.$t('main.text231'));
      }

      if (!this.o_row4.way) {
        return this.msg("error", this.$t('main.text255'));
      }

      if (this.o_row4.way == 1) {
        // 线上
        if (!this.o_row4.platform) {
          return this.msg("error", this.$t('main.text216'));
        }
      } else if (this.o_row4.way == 2) {
        // 线下
        if (!this.o_row4.location) {
          return this.msg("error", this.$t('main.text257'));
        }
        if(this.is_platform){
          if (!this.o_row4.platform) {
            return this.msg("error", this.$t('main.text216'));
          }
        }
        // if(!this.o_row4.online_link){
        //     return this.msg("error",'请输入面试链接（可暂不提供）');
        // }
      }
      if (!this.o_row4.time) {
        return this.msg("error", this.$t('main.text258'));
      }
      if (!this.o_row4.invitation_method) {
        return this.msg("error", this.$t('main.text259'));
      }
      if(!this.o_row2.o_row2_dab){
        return this.msg("error", this.$t('main.text260'));
      }
      if(!this.o_row3_dab){
        return this.msg("error", this.$t('main.text261'));
      }

      // candidates: this.o_row3
      const candidates = [];
      for (const item of this.o_row3) {
        if(item.email&&item.mobile&&item.name){
          candidates.push({
            email: item.email,
            mobile: item.mobile,
            name: item.name,
          })
        }
      }
      let method = [];
      if(this.o_row4.way==2&&this.is_platform){
        method=['2','1'];
      }else if(this.o_row4.way==2&&!this.is_platform){
        method=['2'];
      }else if(this.o_row4.way==1){
        method=['1'];
      }
      
      if(this.status==1){
        // 开启前编辑工单

        // interval: this.interval
        const time_list=[];
        for (const item of this.interval) {
          if(item.start&&item.end&&item.skip_time!==''){
            time_list.push({
              start:item.start,
              end:item.end,
              skip_time:item.skip_time?1:0,
              interval_id:item.interval_id||"",
            })
          }
        }

        let api_data = {
          order_id: this.work_edit_id,
          job: this.o_row1.job, //⼯作
          interviewer: {
            name: this.o_row2.name,
            email: this.o_row2.email,
            country_code: "86",
            mobile: this.o_row2.mobile,
          },
          interval: time_list,
          remarks: "",
          candidates: candidates,
          method,
          location: this.o_row4.location,
          online_platform: this.o_row4.platform,
          // online_link: this.o_row4.online_link,
          duration: this.o_row4.time * 60 * 1000,
          invite_method: [this.o_row4.invitation_method],
          start_invite_time:this.o_row5.start_invite_time||0
        };

        // console.log(api_data);
        const res = await editNotStart(api_data);
        if (res.data.code != 200) {
          return this.msg("error", res.data.msg);
        }
        this.msg("success", this.$t('main.text44'));
        this.$router.push("/");
      }else{
        // 开启后编辑工单

        // interval: this.interval
        const time_list=[];
        for (const item of this.interval) {
          if(item.start&&item.end&&item.skip_time!==''){
            time_list.push({
              start:item.start,
              end:item.end,
              skip_time:item.skip_time?1:0,
              status:item.status,
              interval_id:item.interval_id||"",
            })
          }
        }

        let api_data = {
          order_id: this.work_edit_id,
          interviewer: {
            name: this.o_row2.name,
            email: this.o_row2.email,
            country_code: "86",
            mobile: this.o_row2.mobile,
          },
          interval: time_list,
          remarks: "",
          candidates: candidates,
          method,
          location: this.o_row4.location,
          online_platform: this.o_row4.platform,
          // online_link: this.o_row4.online_link,
          duration: this.o_row4.time * 60 * 1000,
          invite_method: [this.o_row4.invitation_method],
        };
        // console.log(api_data);
        const res = await editAfterStart(api_data);
        if (res.data.code != 200) {
          return this.msg("error", res.data.msg);
        }
        this.msg("success", this.$t('main.text44'));
        this.$router.push("/");
      }
      
    },
    async get_workOrder_edit_list() {
      const res = await workOrder_get({
        order_id: this.work_edit_id,
      });
      if (res.data.code != 200) {
        return this.msg("error", res.data.msg);
      }
      const d = res.data.data;
      this.o_row1.job = d.job; //⼯作
      // this.o_row2 = d.interviewer; //⾯试官信息
      this.o_row2.name = d.interviewer.name;
      this.o_row2.email = d.interviewer.email;
      this.o_row2.mobile = d.interviewer.mobile;
      this.o_row2.country_code = d.interviewer.country_code;
      // this.interval = d.interval.map(obj=>{
      //   obj.skip_time = obj.skip_time==1 ? true : false;
      //   return obj;
      // })
      let interval = [];
      for (const item of d.interval) {
        interval.push({
          start:item.start,
          end:item.end,
          skip_time:item.skip_time==1 ? true : false,
          status:item.status,
          status_dab:item.status==5 ? true : false,
          interval_id:item.interval_id||"",
        })
      }
      this.interval = interval;
      // console.log(interval);
      this.old_interval = JSON.stringify(interval);
      
      this.o_row3 = d.candidates;
      this.old_o_row3 = JSON.stringify(d.candidates);
      this.o_row4.way = d.method[0] + "";
      if(d.method[1]&&d.method[1]=='1'){
        this.is_platform=true;
      }
      this.o_row4.location = d.location;
      this.o_row4.platform = d.online_platform;
      this.o_row4.online_link = d.online_link;
      this.o_row4.time = d.duration / 1000 / 60 + "";
      this.o_row4.invitation_method = d.invite_method[0] + "";
      this.o_row5.start_invite_time = d.start_invite_time||"";
    },
    // 候选人列表
    open_candidate_dlg() {
        this.tableData=[];
        this.candidate_dlg = true;
        this.reset();
    },
    current_change(v) {
      this.page = v;
      this.limit = 10;
      this.get_candidate_list();
    },
    size_change(v) {
      this.limit = v;
      this.get_candidate_list();
    },
    async get_candidate_list() {
      const res = await candidates_list({
        page: this.page,
        limit: this.limit,
        search_content: this.form.search_content,
      });
      if (res.data.code != 200) {
        return this.msg("error", res.data.msg);
      }
      // this.tableData = res.data.data.list;
      let d = res.data.data.list;
        for (const item of d) {
            if(! ("work_order_checked" in item)){
                item.work_order_checked = false;
            }
            if(item.work_order_checked){
                item.work_order_checked = false;
            }
        }
        this.tableData = d;
        this.new_work_data=[];
        this.total = res.data.data.countAll;
    },
    search() {
      this.page = 1;
      this.limit = 10;
      this.currentPage = 1;
      this.get_candidate_list();
    },
    reset() {
      this.page = 1;
      this.limit = 10;
      this.currentPage = 1;
      this.form.search_content = "";
      this.get_candidate_list();
    },
    table_checked(row){
        // console.log(row);
        // 选中的拼接数组 传值回去
        const index = this.new_work_data.findIndex(item => item.userid == row.userid);
        if(index !== -1){
            this.new_work_data.splice(index, 1);
        }else{
            const d = {
                userid:row.userid,
                name:row.name,
                mobile:row.mobile,
                email:row.email,
            }
            this.new_work_data.push(d);
        }
    },
    candidate_dlg_confirm(){
        this.candidate_dlg = false;
        if(
            !this.o_row3[0].name&&
            !this.o_row3[0].mobile&&
            !this.o_row3[0].email
        ){
            this.o_row3.splice(0,1);
            this.dlg_candidate_add();
        }else{
            this.dlg_candidate_add();
        }
    },
    dlg_candidate_add(){
        // 去重
        for (const item of this.new_work_data) {
            if(!this.o_row3.some(obj=>obj.userid === item.userid)){
                this.o_row3.push(item);
            }
        }
    },
    o_row2_reset(){
      this.interval = JSON.parse(this.old_interval);
      this.o_row2.o_row2_dab = false;
    },
    o_row3_reset(){
      this.o_row3 = JSON.parse(this.old_o_row3);
      this.o_row3_dab=false;
    },
    r3_delect(ind){
      this.o_row3.splice(ind,1);
    },
    tabbing_order(i,ind){
      let d = this.o_row3[ind];
      this.o_row3.splice(ind, 1);
      if(i==1){//上
        this.o_row3.splice(ind - 1, 0, d);
      }else{//下
        this.o_row3.splice(ind + 1, 0, d);
      }
    },
    time_change(index){
      if(!this.o_row4.time){
        return this.msg("error", this.$t('main.text221'));
      }
      let start = this.interval[index].start;
      let end = this.interval[index].end;
      
      if(start&&end){
        // 分钟
        let h = (end-start)/1000/60;
        if(h<this.o_row4.time){
          this.$set(this.interval[index], "start","");
          this.$set(this.interval[index], "end","");
          return this.msg("error", this.$t('main.text263'));
        }
        // 面试时间包含非工作时间，请再确认面试时间是否准确
        for (const item of this.interval) {
          let s_h = moment(item.start).format('HH');
          let e_h = moment(item.end).format('HH');
          if(s_h<9||s_h>18||e_h<9||e_h>18){
            this.time_err_txt3=true;
            break;
          }else{
            this.time_err_txt3=false;
          }
        }
      }
      
    },
    start_invite_time_change(v){
      if(v){
        // let h = moment(v).format('YYYY/MM/DD HH:mm');
        let h = moment(v).format('HH');
        if(h<9 || h>18){
          this.time_err_txt2=true;
        }else{
          this.time_err_txt2=false;
        }

        for (const item of this.o_row2.time_list) {
          let start_num = Math.abs(v - item.start);
          if(start_num<3600000){
            this.time_err_txt1=true;
            break;
          }else{
            this.time_err_txt1=false;
          }
        }
      }
    }

  },
};
</script>

<style scoped>
.new_order_row {
  border-radius: 6px;
  background: #fff;
  box-sizing: border-box;
  padding: 30px 20px;
  margin-bottom: 30px;
}
.o_title {
  color: #333;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 35px;
}
.row_form >>> .el-form-item__label {
  color: #838383;
}
.row_form >>> .el-form-item:last-child {
  margin-bottom: 0;
}
.parting_line {
  box-sizing: border-box;
  padding: 0 10px;
}
.form_margin_btm {
  margin-bottom: 22px !important;
}
.hint {
  color: #838383;
  font-size: 12px;
  margin-bottom: 10px;
}
.my_label {
  color: #333;
  font-size: 16px;
  margin-right: 35px;
  line-height: 40px;
}
.inp_min_width,
.inp_min_width >>> .el-form-item__content {
  min-width: 300px;
}
.inp_min_width >>> .el-select {
  width: 100%;
}
.order_submit {
  min-width: 350px;
  margin-left: 37px;
  margin-top: 20px;
}
.order_return{
    margin-bottom: 10px;
    cursor: pointer;
}
</style>

<style scoped>
.h_table {
  border-radius: 6px;
  background: #fff;
  box-sizing: border-box;
  /* padding: 20px; */
}
.h_table_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #333;
  font-size: 16px;
  margin-bottom: 12px;
}
.t_top_Right > div {
  display: inline-block;
}
.t_top_2 {
  max-width: 160px;
  margin-right: 20px;
}
.t_top_2 >>> .el-input__inner,
.t_top_3 >>> .el-input__inner {
  border-color: #a9a9a9;
  color: #bcbcbc;
}
.t_top_3 {
  max-width: 200px;
  margin-right: 30px;
  position: relative;
}
.t_top_3 i {
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: #bcbcbc;
  font-size: 20px;
}
.t_top_3 >>> .el-input__inner {
  text-indent: 2em;
}
.t_top_3 >>> .el-input.is-active .el-input__inner,
.t_top_3 >>> .el-input__inner:focus {
  border-color: #409eff;
}
.dlg_main {
  margin: auto;
  width: 350px;
}
.dlg_main >>> .el-form-item__content {
  max-width: 280px;
}
.dlg_main >>> .el-form-item__label {
  color: #838383;
}
.dlg_main >>> .el-select {
  width: 100%;
}
.posi_re{
  position: relative;
}
.span_time{
  position: absolute;
  right: 10px;
  top: 0;
}
</style>
